var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rn_my_wrap"},[_c('div',{staticClass:"rn_my_tit_3"},[_vm._v(" 찜 "),_c('div',{staticClass:"search"},[_c('div',{staticClass:"input_box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],attrs:{"type":"text","name":"","placeholder":_vm.menuName === 'reviewer'
              ? '리뷰어를 검색하세요.'
              : _vm.menuName === 'review'
              ? '리뷰를 검색하세요.'
              : '램 가게를 검색하세요.'},domProps:{"value":(_vm.keyword)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getWishDataSearch.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value}}})]),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.getWishDataSearch.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/sub/alarm_search_bt.png"),"alt":""}})])],1)]),_c('WishMainMenu',{ref:"wishMainMenu",attrs:{"isMoreMenu":_vm.isMoreMenu,"mainMenuList":_vm.mainMenuList},on:{"getMenu":_vm.getMenu}}),_c('div',{staticClass:"rn_ms_section"},[_c('div',{staticClass:"rn_my_empty_10"}),(parseInt(_vm.menu) === 1 && _vm.menuName === 'reviewer')?_c('WishGoodsReviewerAndShopList',{attrs:{"listArray":_vm.listArray,"data":{
        page: _vm.page,
        totalPage: _vm.totalPage,
        limit: _vm.limit,
        menuName: _vm.menuName,
      }},on:{"getWishData":_vm.getWishData,"setTapMenu":_vm.setTapMenu}}):_vm._e(),(parseInt(_vm.menu) === 1 && _vm.menuName === 'review')?_c('WishGoodsReviewList',{attrs:{"listArray":_vm.listArray,"data":{
        page: _vm.page,
        totalPage: _vm.totalPage,
        limit: _vm.limit,
        menuName: _vm.menuName,
      }},on:{"getWishData":_vm.getWishData,"setTapMenu":_vm.setTapMenu}}):_vm._e(),(parseInt(_vm.menu) === 1 && _vm.menuName === 'shop')?_c('WishGoodsReviewerAndShopList',{attrs:{"listArray":_vm.listArray,"data":{
        page: _vm.page,
        totalPage: _vm.totalPage,
        limit: _vm.limit,
        menuName: _vm.menuName,
      }},on:{"getWishData":_vm.getWishData,"setTapMenu":_vm.setTapMenu}}):_vm._e(),(parseInt(_vm.menu) === 3 && _vm.menuName === 'reviewer')?_c('WishNewsReviewerList',{attrs:{"listArray":_vm.listArray,"data":{
        page: _vm.page,
        totalPage: _vm.totalPage,
        limit: _vm.limit,
        menuName: _vm.menuName,
      }},on:{"getWishData":_vm.getWishData,"setTapMenu":_vm.setTapMenu}}):_vm._e(),(parseInt(_vm.menu) === 3 && _vm.menuName === 'review')?_c('WishNewsReviewList',{attrs:{"listArray":_vm.listArray,"data":{
        page: _vm.page,
        totalPage: _vm.totalPage,
        limit: _vm.limit,
        menuName: _vm.menuName,
      }},on:{"getWishData":_vm.getWishData,"setTapMenu":_vm.setTapMenu}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }