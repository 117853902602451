<template>
  <div class="rn_my_slide">
    <div class="left_slide" v-if="isMoreMenu">
      <router-link to="">&lt;</router-link>
    </div>
    <div class="slide_cate" :style="isMoreMenu ? '' : { width: '100%' }">
      <div class="slide_box">
        <div class="swiper_box" v-if="isMoreMenu">
          <swiper
            class="swiper my_wish"
            :options="swiperOption"
            ref="swiperWrap"
            v-if="mainMenuList.length > 0"
          >
            <swiper-slide
              v-for="item in mainMenuList"
              :key="item.seq"
              :data-menu="item.seq"
            >
              {{ item.title }}
            </swiper-slide>
          </swiper>
        </div>
        <div class="box swiper" v-else>
          <div
            v-for="(item, idx) in mainMenuList"
            :key="item.seq"
            @click="checkMenu(item.seq, $event)"
            :class="{ on: idx < 1 }"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="right_slide" v-if="isMoreMenu">
      <router-link to="">&gt;</router-link>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
let vm = null;
export default {
  props: {
    isMoreMenu: {
      type: Boolean,
      default: false,
    },
    mainMenuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        loop: true,
        pagination: false,
        navigation: {
          nextEl: ".left_slide",
          prevEl: ".right_slide",
        },
        centeredSlides: true, //처음 슬라이드를 가운데 배치
        slideToClickedSlide: true, //슬라이드 위치로 이동
        on: {
          slideChangeTransitionEnd: function (e) {
            [...document.querySelectorAll(".swiper-slide")].map((sw) =>
              sw.classList.remove("on")
            );
            document
              .querySelector(".swiper-slide.swiper-slide-active")
              .classList.add("on");
            const target = document.querySelector(".swiper-slide-active.on");
            const seq = target.dataset.menu;
            vm.checkMenu(seq);
          },
        },
      },
    };
  },
  created() {
    vm = this;
  },
  computed: {
    swiper() {
      return this.$refs.swiperWrap.swiper;
    },
  },
  methods: {
    checkMenu(num, self = null) {
      if (!this.isMoreMenu) {
        document.querySelector(".swiper div.on").classList.remove("on");
        self.target.classList.add("on");
      }
      //console.log(num);
      this.$emit("getMenu", parseInt(num));
    },
    goSlide(index) {
      // console.log(index);
      if (this.isMoreMenu) {
        let swiper = document.querySelector(".swiper.my_wish").swiper;
        swiper.slideToLoop(index, 0, true);
      } else {
        let box = document.querySelectorAll(".box.swiper div");
        [...box].map((b) => b.classList.remove("on"));
        box[index].classList.add("on");
      }
    },
  },
  components: { Swiper, SwiperSlide },
};
</script>

<style></style>
