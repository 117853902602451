var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rn_ms_box_1"},[_c('div',{staticClass:"rn_ms_tab_2"},[_c('div',{class:{ on: _vm.data.menuName === 'reviewer' }},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.setTapMenu('reviewer')}}},[_vm._v("찜한 리뷰어")])],1),_c('div',{class:{ on: _vm.data.menuName === 'review' }},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.setTapMenu('review')}}},[_vm._v("찜한 리뷰")])],1)]),_c('div',{staticClass:"rn_ms_empty_30"}),(_vm.listArray.length > 0)?_c('div',{staticClass:"rn_ms_sort_2"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sort),expression:"sort"}],attrs:{"name":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sort=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getWishData(true)}]}},[_c('option',{attrs:{"value":"latest"}},[_vm._v("최신순")]),_c('option',{attrs:{"value":"trust"}},[_vm._v("신뢰도순")]),_c('option',{attrs:{"value":"asc"}},[_vm._v("가나다순")])])]),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"list_table"},[_c('table',{attrs:{"summary":""}},[_c('tbody',[_vm._l((_vm.listArray),function(list){return _c('tr',{key:list.seq},[_c('td',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.setWish({
                  wish_yn: list.wish_yn,
                  reviewer_seq: list.reviewer_seq,
                  main_seq: list.main_seq,
                  type: 'reviewer',
                })}}},[(list.wish_yn === 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/wishlist_like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/wishlist_like_off.png"),"alt":""}})])],1),_c('td',{on:{"click":function($event){return _vm.$router.push(
                ("/review/reviewer_news/" + (list.reviewer_seq) + "?from=wish")
              )}}},[_c('h4',[_vm._v(_vm._s(list.title))])]),_c('td',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.setAlarm({
                  alarm_yn: list.alarm_yn,
                  reviewer_seq: list.reviewer_seq,
                  main_seq: list.main_seq,
                })}}},[(list.alarm_yn === 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/wishlist_alim_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/wishlist_alim_off.png"),"alt":""}})])],1)])}),(_vm.listArray.length <= 0)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("찜한 리뷰어가 없습니다.")])]):_vm._e()],2)])]),(parseInt(_vm.data.totalPage) > parseInt(_vm.data.page))?_c('div',{staticClass:"rn_more"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.getWishData()}}},[_vm._v(_vm._s(parseInt(_vm.data.limit))+"개 더보기"),_c('img',{attrs:{"src":require("@/assets/images/sub/my_down_ico.png"),"alt":""}})])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{attrs:{"href":"#"}},[_vm._v("알림관리")])])}]

export { render, staticRenderFns }