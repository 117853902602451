<template>
  <div class="rn_ms_box_1">
    <!---->
    <div class="rn_ms_tab_1">
      <div :class="{ on: data.menuName === 'reviewer' }">
        <router-link to="" @click.native="setTapMenu('reviewer')"
          >찜한 리뷰어</router-link
        >
      </div>
      <div :class="{ on: data.menuName === 'review' }">
        <router-link to="" @click.native="setTapMenu('review')"
          >찜한 리뷰</router-link
        >
      </div>
      <div :class="{ on: data.menuName === 'shop' }">
        <router-link to="" @click.native="setTapMenu('shop')"
          >찜한 가게</router-link
        >
      </div>
    </div>
    <!---->
    <div class="rn_ms_empty_30"></div>
    <!---->
    <div class="rn_ms_sort_2" v-if="listArray.length > 0">
      <div>
        <select name="" v-model="sort" @change="getWishData(true)">
          <option value="latest">최신순</option>
          <option value="trust">신뢰도순</option>
          <option value="asc">가나다순</option>
        </select>
      </div>
      <div><router-link to="">알림관리</router-link></div>
    </div>
    <!---->
    <div class="list_table">
      <table summary="">
        <tbody>
          <tr v-for="(list, index) in listArray" :key="list.seq">
            <td>
              <router-link
                to=""
                @click.native="
                  setWish({
                    wish_yn: list.wish_yn,
                    reviewer_seq: list.reviewer_seq,
                    main_seq: list.main_seq,
                    type: 'reviewer',
                  })
                "
              >
                <img
                  src="@/assets/images/sub/wishlist_like_on.png"
                  alt=""
                  v-if="list.wish_yn === 'Y'"
                />
                <img
                  src="@/assets/images/sub/wishlist_like_off.png"
                  alt=""
                  v-else
                />
              </router-link>
            </td>
            <td>
              <h4 @click="movePage(index)">{{ list.title }}</h4>
              <!-- <p>다양한 신상품은 여기서</p> -->
            </td>
            <td>
              <router-link
                to=""
                @click.native="
                  setAlarm({
                    alarm_yn: list.alarm_yn,
                    reviewer_seq: list.reviewer_seq,
                    main_seq: list.main_seq,
                  })
                "
              >
                <img
                  src="@/assets/images/sub/wishlist_alim_on.png"
                  alt=""
                  v-if="list.alarm_yn === 'Y'"
                />
                <img
                  src="@/assets/images/sub/wishlist_alim_off.png"
                  alt=""
                  v-else
                />
              </router-link>
            </td>
          </tr>
          <tr v-if="listArray.length <= 0">
            <td colspan="2">
              찜한 {{ data.menuName === "reviewer" ? "리뷰어" : "가게" }}가
              없습니다.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!---->
    <div class="rn_more" v-if="parseInt(data.totalPage) > parseInt(data.page)">
      <router-link to="" @click.native="getWishData()"
        >{{ parseInt(data.limit) }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort: "latest",
    };
  },
  props: {
    listArray: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    movePage(index) {
      const data = this.listArray[index];
      let url = "";
      if (this.data.menuName === "shop") {
        url = `/mini_shop/${data.provider_seq}`;
      } else {
        url = `/review/reviewer_goods/${data.reviewer_seq}`;
      }
      this.$router.push(url);
    },
    setAlarm(params) {
      let msg = "";
      if (params.alarm_yn === "Y") {
        params.alarm_yn = "N";
        msg = "알림을 해제 하시겠습니까?";
      } else {
        params.alarm_yn = "Y";
        msg = "알림을 설정 하시겠습니까?";
      }
      this.$confirm(msg)
        .then(async () => {
          // console.log(params);
          if (this.data.menuName === "reviewer") {
            await this.$store.dispatch("wish/updateWishAlarm", params);
          } else {
            await this.$store.dispatch("wish/updateWishShopAlarm", params);
          }
          this.$toast.default(this.$store.state.wish.msg);
        })
        .catch(() => {
          return false;
        });
    },
    setWish(params) {
      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }
      this.$confirm("찜을 삭제 하시겠습니까?")
        .then(async () => {
          // console.log(params);
          if (this.data.menuName === "reviewer") {
            await this.$store.dispatch("wish/updateWish", params);
          } else {
            await this.$store.dispatch("wish/updateWishShop", params);
          }
          this.$toast.default(this.$store.state.wish.msg);
        })
        .catch(() => {
          return false;
        });
    },
    getWishData(reset = false) {
      this.$emit("getWishData", reset, this.sort);
    },
    setTapMenu(menuName) {
      this.sort = "latest";
      this.$emit("setTapMenu", menuName);
    },
  },
};
</script>

<style></style>
