<template>
  <div class="rn_ms_box_2">
    <!---->
    <div class="rn_ms_tab_2">
      <div :class="{ on: data.menuName === 'reviewer' }">
        <router-link to="" @click.native="setTapMenu('reviewer')"
          >찜한 리뷰어</router-link
        >
      </div>
      <div :class="{ on: data.menuName === 'review' }">
        <router-link to="" @click.native="setTapMenu('review')"
          >찜한 리뷰</router-link
        >
      </div>
    </div>
    <!---->
    <div class="rn_ms_empty_30"></div>
    <!---->
    <div class="rn_ms_sort_1" v-if="listArray.length > 0">
      <div>
        <select name="" v-model="sort" @change="getWishData(true)">
          <option value="latest">최신순</option>
          <option value="trust">신뢰도순</option>
          <option value="asc">가나다순</option>
        </select>
      </div>
    </div>
    <!---->
    <div class="list_table">
      <table summary="">
        <tbody>
          <tr v-for="(list, index) in listArray" :key="list.seq">
            <td>
              <router-link
                to=""
                @click.native="
                  setWish({
                    wish_yn: list.wish_yn,
                    review_seq: list.review_seq,
                    main_seq: list.main_seq,
                    type: 'review',
                  })
                "
              >
                <img
                  src="@/assets/images/sub/wishlist_like_on.png"
                  alt=""
                  v-if="list.wish_yn === 'Y'"
                />
                <img
                  src="@/assets/images/sub/wishlist_like_off.png"
                  alt=""
                  v-else
                />
              </router-link>
            </td>
            <td>
              <h4 @click="movePage(index)">{{ list.title }}</h4>
              <ul>
                <li>{{ list.nickname }}</li>
                <li>
                  리뷰어 신뢰도&nbsp;&nbsp;{{ rammFloat(list.trust_score) }}
                </li>
                <li>{{ day(list.modi_dt ? list.modi_dt : list.reg_dt) }}</li>
              </ul>
            </td>
          </tr>
          <tr v-if="listArray.length <= 0">
            <td colspan="2">찜한 리뷰가 없습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!---->
    <div class="rn_more" v-if="parseInt(data.totalPage) > parseInt(data.page)">
      <router-link to="" @click.native="getWishData()"
        >{{ parseInt(data.limit) }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sort: "latest",
    };
  },
  props: {
    listArray: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    movePage(index) {
      const data = this.listArray[index];
      const url = `/review/news_main/${data.option_category1}?category=${data.option_category2}&seq=${data.review_seq}`;
      this.$router.push(url);
    },
    day(str) {
      return this.$moment(str).format("YY-MM-DD");
    },
    async setWish(params) {
      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }
      this.$confirm("찜을 삭제 하시겠습니까?")
        .then(async () => {
          // console.log(params);
          await this.$store.dispatch("wish/updateWish", params);
          this.$toast.default(this.$store.state.wish.msg);
        })
        .catch(() => {
          return false;
        });
    },
    getWishData(reset = false) {
      this.$emit("getWishData", reset, this.sort);
    },
    setTapMenu(menuName) {
      this.$emit("setTapMenu", menuName);
    },
    rammFloat(num) {
      return this.$rammFloat(num);
    },
  },
};
</script>

<style></style>
