<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      찜
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="keyword"
            :placeholder="
              menuName === 'reviewer'
                ? '리뷰어를 검색하세요.'
                : menuName === 'review'
                ? '리뷰를 검색하세요.'
                : '램 가게를 검색하세요.'
            "
            @keypress.enter="getWishDataSearch"
          />
        </div>
        <router-link to="" @click.native="getWishDataSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <WishMainMenu
      :isMoreMenu="isMoreMenu"
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      ref="wishMainMenu"
    />
    <!---->
    <div class="rn_ms_section">
      <div class="rn_my_empty_10"></div>
      <!---->
      <WishGoodsReviewerAndShopList
        v-if="parseInt(menu) === 1 && menuName === 'reviewer'"
        @getWishData="getWishData"
        @setTapMenu="setTapMenu"
        :listArray="listArray"
        :data="{
          page,
          totalPage,
          limit,
          menuName,
        }"
      />
      <WishGoodsReviewList
        v-if="parseInt(menu) === 1 && menuName === 'review'"
        @getWishData="getWishData"
        @setTapMenu="setTapMenu"
        :listArray="listArray"
        :data="{
          page,
          totalPage,
          limit,
          menuName,
        }"
      />
      <WishGoodsReviewerAndShopList
        v-if="parseInt(menu) === 1 && menuName === 'shop'"
        @getWishData="getWishData"
        @setTapMenu="setTapMenu"
        :listArray="listArray"
        :data="{
          page,
          totalPage,
          limit,
          menuName,
        }"
      />
      <WishNewsReviewerList
        v-if="parseInt(menu) === 3 && menuName === 'reviewer'"
        @getWishData="getWishData"
        @setTapMenu="setTapMenu"
        :listArray="listArray"
        :data="{
          page,
          totalPage,
          limit,
          menuName,
        }"
      />
      <WishNewsReviewList
        v-if="parseInt(menu) === 3 && menuName === 'review'"
        @getWishData="getWishData"
        @setTapMenu="setTapMenu"
        :listArray="listArray"
        :data="{
          page,
          totalPage,
          limit,
          menuName,
        }"
      />
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import WishMainMenu from "@/components/wish/list/WishMainMenu";
import WishGoodsReviewerAndShopList from "@/components/wish/list/WishGoodsReviewerAndShopList";
import WishGoodsReviewList from "@/components/wish/list/WishGoodsReviewList";
import WishNewsReviewerList from "@/components/wish/list/WishNewsReviewerList";
import WishNewsReviewList from "@/components/wish/list/WishNewsReviewList";
export default {
  data() {
    return {
      defaultCnt: 5, //이상이면 swiper library 적용
      isMoreMenu: true,
      menuName: this.$route.params.menuName || "reviewer",
      menu: this.$route.query.menu || 1,
      page: 0,
      keyword: "",
      sort: "latest",
      first: true,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      wish: true,
    });
    this.$store.dispatch("wish/dataClear");
    this.getWishMenu();
  },
  computed: {
    ...mapState("wish", [
      "result",
      "msg",
      "mainMenuList",
      "listArray",
      "totalPage",
      "limit",
    ]),
    getId() {
      const id = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(this.allowMenu[0])
      );
      return id;
    },
  },
  methods: {
    async getWishMenu() {
      await this.$store.dispatch("wish/getWishMenu");

      // 찜한 데이터가 하나도 없다면
      // if (this.mainMenuList.length <= 0) {
      //   this.$toast.default("찜 한 정보가 없습니다.");
      //   this.$router.push("/mypage/my_info");
      //   return false;
      // }
      this.isMoreMenu = parseInt(this.mainMenuList.length) > this.defaultCnt;
      // this.menu = parseInt(this.mainMenuList[0].seq);
      this.getWishData(true);
    },
    getWishDataSearch() {
      // if (this.keyword.replace(/\s/g, "").length <= 0) {
      //   this.$toast.default("검색어를 입력해 주세요.");
      //   return false;
      // }
      this.getWishData(true);
    },
    async getWishData(reset = false, sort = null) {
      if (reset) {
        this.page = 0;
      }
      if (sort) {
        this.sort = sort;
      }
      await this.$store.dispatch("wish/getWishData", {
        main_menu: this.menu,
        page: this.page,
        sort: this.sort,
        keyword: this.keyword,
        wish_type: this.menuName,
        reset,
      });
      this.page++;
      if (this.mainMenuList.length > 0) {
        const index = this.getMainIndex();
        this.$refs.wishMainMenu.goSlide(index);
      }
    },
    getMainIndex() {
      const index = this.mainMenuList.findIndex(
        (main) => parseInt(main.seq) === parseInt(this.menu)
      );

      return index;
    },
    getMenu(num) {
      // if (!this.first) {
      this.menu = num;
      this.sort = "latest";
      this.menuName = "reviewer";
      this.keyword = "";
      // console.log(this.menu);
      this.getWishData(true);
      // }
      // this.first = false;
    },
    setTapMenu(menuName) {
      this.menuName = menuName;
      this.sort = "latest";
      this.keyword = "";

      this.getWishData(true);
    },
    changSort(sort) {
      this.sort = sort;
      this.getReviewList(true);
    },
  },
  components: {
    WishMainMenu,
    WishGoodsReviewerAndShopList,
    WishGoodsReviewList,
    WishNewsReviewerList,
    WishNewsReviewList,
  },
};
</script>

<style></style>
